<template>
  <FocusTrap>
    <div  id="mycard" class="card">

    <div class="card-header" style="padding: 0px">
      <div class="page-header page-header-light" >
        <div class="page-header-content header-elements-lg-inline" >
          <div class="page-title d-flex" style="padding: 0px;">
            <h6>
              <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Sale </span> - List
            </h6>
            <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
          </div>

          <div class="header-elements d-none">
            <form action="#">
              <div class="form-group">

                <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                  <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                  <div class="form-control-feedback">
                    <i class="icon-search4"></i>
                  </div>

                  <div class="input-group-append position-static">
                    <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                      <i class="icon-gear"></i>
                    </button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <div class="table-responsive">
      <table id="invoice_list_table"
             class="table  table-hover  table-bordered table-condensed table-columned"
             data-search="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-pagination="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-row-style="rowStyle"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="status" data-class="d-none">Status</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
          <th data-field="doc_no" data-width="100" data-sortable="true">Inv No</th>
          <th data-field="type" data-width="100" data-formatter="transactionTypeFormatter" data-sortable="true">Type</th>
          <th data-field="buyer.name" data-sortable="true">Party</th>
          <!--<th data-field="ref_no" data-width="100" data-sortable="true">Inv No</th>-->
          <!--<th data-field="ref_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Inv Date</th>-->

          <th data-field="gross_amt" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Taxable</th>
          <th data-field="tax_amt" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Tax</th>
          <th data-field="net_amt" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Amount</th>
          <th data-field="remarks" data-sortable="true">Remarks</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">


        <a href="#" class="dropdown-item"><i class="icon-printer" ></i>Print Preview 1</a>
        <a href="#" class="dropdown-item"><i class="icon-printer" ></i>Print Preview 2</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
<!--        <a href="#" class="dropdown-item" ><i class="icon-file-eye2" ></i>Preview</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify Invoice</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red" @click="removeDocument"> <i class="icon-blocked" ></i>Cancel</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <vmodal name="invoice_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @closed="afterClose" >
      <SaleInvoiceForm v-if="invoice.id > 1" v-bind:myvoucher="invoice" v-on:sale_invoice_saved="loadData" v-on:saleinvoice_window_closed="closeInvoiceWindow" />
    </vmodal>


    <!-- The Modal -->
    <div class="modal" id="invoiceModal" >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <SaleInvoiceForm v-if="invoice.id > 1" v-bind:myvoucher="invoice" v-on:sale_invoice_saved="loadData" v-on:saleinvoice_window_closed="closeInvoiceWindow" ></SaleInvoiceForm>
          </div>


        </div>
      </div>
    </div>
    <!--/The Modal -->


    <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
      <form>
        <div class="card">
          <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
            <h3 class="card-title">Filter</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">From:</label>
              <div class="col-md-3">
                <input id= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" />
              </div>

              <label class="col-md-3 col-form-label">Upto</label>
              <div class="col-md-3">
                <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
              </div>
            </div>


            <div class="form-group row">
              <label class="col-md-3 col-form-label">Sale Type:</label>
              <div class="col-md-9">
                <select id="cmbtypes" class="form-control" v-model="saletype" >
                  <option value="0" selected>All</option>
                  <option value="404">B2B-Local</option>
                  <option value="405">B2C-Local</option>
                  <option value="406">Export</option>
                  <option value="407">B2B-Others</option>
                  <option value="408">B2C-Others</option>
                  <option value="409">Exempted</option>
                </select>
              </div>
            </div>


            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

    <!-- modal -->
    <div id="fsPrintPreview"
         class="modal animated bounceIn"
         tabindex="-1"
         role="dialog"
         aria-labelledby="myModalLabel"
         aria-hidden="true">

        <!-- dialog -->
        <div class="modal-dialog modal-full">

          <!-- content -->
          <div class="modal-content" style="padding: 0px;">

            <!-- body -->
            <div class="modal-body">
              <ReportViewer v-if="can_print" v-bind:reportfilename="filename" v-bind:json_data=invoice v-bind:report_url="reppath"></ReportViewer>
            </div>
            <!-- body -->

          </div>
          <!-- content -->

        </div>
        <!-- dialog -->

    </div>
    <!-- modal -->

  </div>
  </FocusTrap>
</template>

<script>
  import SaleInvoiceForm from './SaleInvoiceForm';
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import ReportViewer from "@/views/tools/ReportViewerByJson";

  export default {
    name: 'SaleInvoiceView',
    components: {
      ReportViewer,
      SaleInvoiceForm,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        invoice: JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}'),
        series: 404,
        saletype: 0,
        from_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        can_print: false,
        isActive: false,
        filename: 'invoice.mrt',
        reppath: '',
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      this.$data.mytable = $('#invoice_list_table');
      this.$data.mytable.bootstrapTable();
      $('#invoice_list_table').on('post-body.bs.table', function (e) {
        $('[data-toggle="popover"]').popover();
      });

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {


          let id = $(row.children('*')[0]).text();
          let status =  $(row.children('*')[1]).text();

          if ($(e.target).text() === 'Modify Invoice') {
            //if (status === 0) {
              self.modifyDocument(id);
            //}
          }else if ($(e.target).text() == 'Print Preview 1'){
            self.printPreview(id);
          }else if ($(e.target).text() == 'Print Preview 2'){
            self.printPreview2(id);
          }else if ($(e.target).text() === 'Cancel') {
             if(status === 'Active') {
              self.removeDocument(id);
             }
          }else if ($(e.target).text() == 'Preview'){
            self.invoicePreview(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#invoice_list_table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.$data.from_date = moment().add(-7,'d').format('YYYY-MM-DD');

      self.isActive = false;
      self.$modal.show('filter_window');

      $('#fsPrintPreview').on('hidden.bs.modal', function (e) {
        self.isActive = false;
        self.reppath = '';
      });

    },
    methods: {
      afterfilterwindow_visible(){
        $('#txtfrom').focus();
      },
      showFilterWindow(){
        this.$modal.show('filter_window');
      },
      closeModal() {
        this.$modal.hide('invoice_window');
        $('#previewmodal').modal('hide');
      },
      beforeOpen(){

      },
      afterClose(){
        this.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}');
        // this.$data.invoice = JSON.parse('{"id":0,"ver":0,"status":0,"is_cr":"N","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
      },
      showModal () {
        //this.$modal.show('invoice_window');
        $('#invoiceModal').modal('show');
      },
      closeInvoiceWindow(){
        this.$modal.hide('invoice_window');
        $('#invoiceModal').modal('hide');
      },
      blockModal(){
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
      },
      invoicePreview(id){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.invoice = {};

        fetch(`${process.env.VUE_APP_ROOT_API}api/sale/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
            $('#previewmodal').modal('show');

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        });

      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        // alert(`${process.env.VUE_APP_ROOT_API}api/sale/${id}`);
        self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/sale/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');


            $('#invoiceModal').modal('show');

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.invoice.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.invoice)
        };

        swal({
          title: 'What is the reason for cancel this transaction?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/sale?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('receipt-window');
              if(resp.ok){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.msg, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });


      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#txtsearch').val('');

        self.$modal.hide('filter_window');
        self.$modal.hide('invoice_window');
        $('#previewmodal').modal('hide');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);
        self.$data.mytable.bootstrapTable('showLoading');

        fetch(`${process.env.VUE_APP_ROOT_API}api/sales?from=${self.$data.from_date}&upto=${self.$data.upto_date}&type=${self.$data.saletype}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( Array.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
            self.$modal.hide('filter_window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
        });

      },
      printPreview(id){
        const self = this;

        self.$data.reppath =  '';

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        // alert(`${process.env.VUE_APP_ROOT_API}api/sale/print/${id}`);

        self.$data.can_print = false;
        self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/sale/print/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (!_.isNull(resp.data)) {

              self.$data.filename = 'invoice_1.mrt';
              self.$data.invoice = resp;
              self.$data.invoice.data.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
              self.$data.invoice.data.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
              self.$data.reppath =  `${process.env.VUE_APP_ROOT_API}api/sale/${id}`;
              self.$data.can_print = true;

              $('#fsPrintPreview').modal('show');
              // setTimeout(function (){
              //   $('#fsPrintPreview').modal('hide');
              // }, 1000);

            }
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })


      },
      printPreview2(id){
        const self = this;

        self.$data.reppath =  '';

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        // alert(`${process.env.VUE_APP_ROOT_API}api/sale/print/${id}`);

        self.$data.can_print = false;
        self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/sale/print/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (!_.isNull(resp.data)) {

              self.$data.filename = 'invoice_2.mrt';
              self.$data.invoice = resp;
              self.$data.invoice.data.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
              self.$data.invoice.data.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
              self.$data.reppath =  `${process.env.VUE_APP_ROOT_API}api/sale/${id}`;
              self.$data.can_print = true;

              $('#fsPrintPreview').modal('show');
              // setTimeout(function (){
              //   $('#fsPrintPreview').modal('hide');
              // }, 1000);

            }
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })


      },

    }

  }
</script>

<style scoped>

  .modal-content {
    padding: 0px;
  }
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
